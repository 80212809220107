<template>
  <div class='key-ratio'>
    <!-- 外汇table切换暂时不用 -->
    
    <!-- <div v-if="[1, 2, 3, 5, 4 ].indexOf(courseType) === -1" style="margin-left: 10px;">
        <el-radio-group v-model="financeRadio" :disabled="disabled" size="small" @change="getList" style="margin:0 -20px;" text-color="#3B6EFB" fill="#FFFF">
          <el-radio-button label="证券" value="证券"/>
          <el-radio-button label="外汇" value="外汇"/>
        </el-radio-group>
      </div> -->
    <tab-table
      :columns="columns"
      :findApi="findKeyRatios"
      :exportApi="exportKeyRatios"
    />
  </div>
</template>

<script>
import tabTable from "../tab-table";
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { findKeyRatios, exportKeyRatios } from '../apis'
import { keyRatioData } from '../datas'
import {} from '../methods'

export default defineComponent({
  name: "key-ratio",
  components:{
    tabTable,
  },
  setup(){
    let data = reactive(keyRatioData)
    onMounted(() => {
        data.courseType = JSON.parse(sessionStorage.getItem('mega')).transactionType
    })
    let getList = () => { console.log('financeRadio',data.financeRadio); }
    
    return{
      ...toRefs(data),
      findKeyRatios,
      exportKeyRatios,
      getList
    }
  }
})
</script>

<style scoped lang="less">
.key-ratio {
  margin-top: 60px;
}
.radio-button {
  border: none;
  color: #dca550;
  &:hover {
    color: #dca550;
  }
}
::v-deep.el-radio-button {
  .el-radio-button__inner {
    background: #FFFF;
    color: #333;
    border: 0 !important;
  }
}
</style>